import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Header } from "semantic-ui-react";
import { editFunds, getFunds, getMembers } from "../features/members/memberSlice";

// Stylesheet
import { Segment } from "semantic-ui-react";
import "../styles/InvestmentForm.css";

const InvestmentForm = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState({ id: "", earnings: 0 });
  const [errors, setErrors] = useState({});

  const { user } = useSelector((state) => state.auth);

  const { membersArr, userFunds, isLoading: isLoadingMbr } = useSelector((state) => state.members);

  useEffect(() => {
    dispatch(getMembers(user.groupid));
  }, [dispatch, user.groupid]);

  const members = membersArr.filter(member => member.groupid === user.groupid && member.withdrawal !== 1);
  
  useEffect(() => {
    dispatch(getFunds(user.id));
  }, [dispatch, user.id]);

  const validateForm = () => {
    const newErrors = {};
    if (!data.id) newErrors.id = 'You must select a participant';
    if (data.earnings <= 0) newErrors.earnings = 'Investment amount must be greater than 0';
  
    return newErrors;
  };


  // Send investment data
  const handleSubmit = async () => {
    
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Stop the submission as there are errors
    }

    try {
      await dispatch(editFunds({ userid: user.id, id: data.id, earnings: data.earnings })).unwrap();
      await dispatch(getFunds(user.id)).unwrap(); //get the updated funds of the logged in user
      await dispatch(getMembers(user.groupid)).unwrap();
      toast.success("Investment successful");
    } catch (err) {
      console.error(err);
      toast.error("Investment failed");
    } finally {
      setData({ id: "", earnings: 0 });
    }
  };

  return (
    <Segment textAlign="center">
      <Form onSubmit={handleSubmit}>
        <Header as="h3" textAlign="center">Investment Form</Header>
        <Form.Dropdown
          placeholder="Select a Participant"
          fluid
          selection
          options={members.map(member => ({ key: member.id, text: `${member.name} ${member.lastname}`, value: member.id }))}
          name="id"
          value={data.id}
          onChange={(e, { value }) => setData({ ...data, id: value })}
          error={errors.id ? { content: errors.id, pointing: 'below' } : null}
          disabled={members.length === 0}
          required
        />
        <Form.Input
          fluid
          type="number"
          label="Amount to invest"
          name="earnings"
          value={data.earnings}
          onChange={(e, { value }) => setData({ ...data, earnings: value })}
          min={0}
          max={userFunds}
          step={10000}
          placeholder="$"
          error={errors.earnings ? { content: errors.earnings, pointing: 'below' } : null}
          disabled={members.length === 0}
          required
          />
        {userFunds === 0 ? (
          <Header as="h3" textAlign="center">Thank you for your investments!</Header>
        ) : (
          <Form.Button color="blue" fluid size="large" disabled={members.length === 0}>Invest</Form.Button>
        )}
      </Form>
    </Segment>
  );
};

export default InvestmentForm;
