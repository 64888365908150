import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import resetService from "./resetService";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const resetFunds = createAsyncThunk(
    "reset/resetFunds",
    async (id, thunkAPI) => {
        try {
            return await resetService.resetFunds(id);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const resetGroupFunds = createAsyncThunk(
    "reset/resetGroupFunds",
    async (id, thunkAPI) => {
        try {
            return await resetService.resetGroupFunds(id);
        } catch (error) {
            const message = error.response.data.message || error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const resetSlice = createSlice({
    name: "reset",
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetFunds.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(resetFunds.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = "Funds Reset";
            })
            .addCase(resetFunds.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(resetGroupFunds.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(resetGroupFunds.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = "Group Funds Reset";
            })
            .addCase(resetGroupFunds.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export const { reset } = resetSlice.actions;

export default resetSlice.reducer;

