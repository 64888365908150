import React from "react";

// Stylesheet
import { useSelector } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import "../styles/Dashboard.css";

const Dashboard = () => {

  const { userFunds } = useSelector((state) => state.members);

  return (
    <Segment textAlign="center">
      <Header as="h2">Your Dashboard</Header>
      <p style={{fontSize: "16PX"}}   >Your funds left for investment: ${userFunds}</p>
    </Segment>
  );
};

export default Dashboard;
