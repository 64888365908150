import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Divider, Dropdown, Form, Grid, Header, Image, Popup, Segment } from 'semantic-ui-react';
import { getAllGroups } from "../features/groups/groupSlice";
import { editFunds, getFunds, getGuests, getMembers } from "../features/members/memberSlice";
import { resetFunds, resetGroupFunds } from "../features/reset/resetSlice";



// Stylesheet
import "../styles/Admin.css";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({ id: '', earnings: 0 });
  const [winners, setWinners] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [errors, setErrors] = useState({});

  const { user } = useSelector((state) => state.auth);

  const { groupsArr, isLoading: isLoadingGrp } = useSelector((state) => state.groups);

  const { membersArr, guestsArr, userFunds, isLoading: isLoadingMbr } = useSelector((state) => state.members);

  const { isLoading: isLoadingRst } = useSelector((state) => state.reset);

  useEffect(() => {
    if (user.role !== "admin") {
      navigate("/participant");
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(getFunds(user.id));
  }, [dispatch, user.id]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    setGroupData(groupsArr);
  }, [groupsArr]);

  useEffect(() => {
    if (selectedGroup) {
      dispatch(getMembers(selectedGroup));
    }
  }, [selectedGroup, dispatch]);

  useEffect(() => {
    dispatch(getGuests());
  }, [selectedGroup, dispatch]);

  const members = membersArr.filter(member => member.groupid === selectedGroup && member.withdrawal !== 1);
  const guests = guestsArr.filter(guest => guest.groupid === selectedGroup);

  useEffect(() => {
    if (members.length > 0) {
      const sortedMembers = [...members].sort((a, b) => b.earnings - a.earnings);
      const topWinners = sortedMembers.slice(0, 3);

      // Only update state if winners have actually changed
      const winnersChanged = JSON.stringify(topWinners) !== JSON.stringify(winners);
      if (winnersChanged) {
        setWinners(topWinners);
      }
    }
  }, [members])

  const resetMyData = async () => {
    try {
      await dispatch(resetFunds(user.id)).unwrap();
      await dispatch(getFunds(user.id)).unwrap();
    } catch (err) {
      console.error(err);
    }
  }

  const resetGroupData = async () => {
    try {
      await dispatch(resetFunds(user.id)).unwrap();
      await dispatch(getFunds(user.id)).unwrap();
      await dispatch(resetGroupFunds(selectedGroup)).unwrap();
      await dispatch(getMembers(selectedGroup)).unwrap();
      await dispatch(getGuests()).unwrap();
    } catch (err) {
      console.error(err);
    }
  }

  const validateForm = () => {
    const newErrors = {};
    if (!data.id) newErrors.id = 'You must select a participant';
    if (data.earnings <= 0) newErrors.earnings = 'Investment amount must be greater than 0';
  
    return newErrors;
  };

  const handleSubmit = async () => {

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Stop the submission as there are errors
    }


    try {
      await dispatch(editFunds({ userid: user.id, id: data.id, earnings: data.earnings })).unwrap();
      await dispatch(getFunds(user.id)).unwrap(); //get the updated funds of the logged in user
      await dispatch(getMembers(selectedGroup)).unwrap();
    } catch (err) {
      console.error(err);
    } finally {
      setData({ id: "", earnings: 0 });
      setErrors({})
    }
  };

  return (
    <Container>
      <Segment>
        <Segment basic loading={isLoadingMbr}>
          <Card.Group centered itemsPerRow={3}>
            {winners.map((winner, index) => (
              <Card fluid  key={index} color={index === 0 ? "yellow" : index === 1 ? "grey" : "brown"} >
                <Card.Content>
                  <Card.Header textAlign="center">{index === 0 ? "First Place" : index === 1 ? "Second Place" : "Third Place"}</Card.Header>
                  <Card.Header style={{ marginTop: "2px" }} >{winner.name} {winner.lastname}</Card.Header>
                  <Card.Description style={{ color: "green", fontSize: "16px" }}>Raised: ${winner.earnings}</Card.Description>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Segment>
      </Segment>
      <Grid divided="vertically">
        <Grid.Row centered columns={2}>
          <Grid.Column>
            <Segment padded>
              <Header as="h2" dividing textAlign="center">Admin Dashboard</Header>
              <Dropdown
                placeholder="Select Group"
                fluid
                selection
                options={groupData.map(group => ({ key: group.id, text: group.groupname, value: group.id }))}
                onChange={(e, { value }) => setSelectedGroup(value)}
                loading={isLoadingGrp}
              />
              <Header as="h3" textAlign="left" style={{ marginLeft: "1em" }}>Your funds left for investment: ${userFunds}</Header>
              
              <Button fluid size="large" color="red" onClick={resetMyData} loading={isLoadingRst} >Reset My Data</Button>
              <Button fluid size="large" color="red" onClick={resetGroupData} style={{marginTop: "10px"}} loading={isLoadingRst} >Reset Group Funds and Earnings</Button>
              <Divider section />
              <Form onSubmit={handleSubmit}>
                <Header as="h3" textAlign="center">Investment Form</Header>
                <Form.Dropdown
                  placeholder="Select a Participant"
                  fluid
                  selection
                  options={members.map(member => ({ key: member.id, text: `${member.name} ${member.lastname}`, value: member.id }))}
                  name="id"
                  value={data.id}
                  onChange={(e, { value }) => {
                    setData(prevData => ({ ...prevData, id: value }));
                    if (errors.id) {
                      setErrors(prevErrors => ({ ...prevErrors, id: undefined }));
                    }
                  }}
                  error={errors.id ? { content: errors.id, pointing: 'below' } : null}
                  disabled={members.length === 0}
                  required
                />
                <Form.Input
                  fluid
                  type="number"
                  label="Amount to invest"
                  name="earnings"
                  value={data.earnings}
                  onChange={(e, { value }) => {
                    setData(prevData => ({ ...prevData, earnings: parseInt(value, 10) }));
                    if (errors.earnings && parseInt(value, 10) > 0) {
                      setErrors(prevErrors => ({ ...prevErrors, earnings: undefined }));
                    }
                  }}
                  min={0}
                  max={userFunds}
                  step={10000}
                  placeholder="$"
                  error={errors.earnings ? { content: errors.earnings, pointing: 'below' } : null}
                  disabled={members.length === 0}
                  required
                  />
                {userFunds === 0 ? (
                  <Header as="h3" textAlign="center">Thank you for your investments!</Header>
                ) : (
                  <Form.Button color="blue" fluid size="large" disabled={members.length === 0}>Invest</Form.Button>
                )}
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment padded loading={isLoadingMbr}>
            {
              (members.length > 0 || guests.length > 0) ? (
                <>
                  {members.map((member, index) => (
                    <Card fluid key={`member-${index}`}>
                      <Card.Content>
                        <Card.Header style={{ color: "#146a96"}} textAlign="center">{member.name} {member.lastname}</Card.Header>
                        <Card.Description>
                          <p style={{ fontSize: "16px" }}>Funds left: ${member.funds}</p>
                          <p style={{ fontSize: "16px" }}>Earnings: ${member.earnings}</p>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ))}
                  {guests.map((guest, index) => (
                    <Card fluid key={`guest-${index}`}>
                      <Card.Content>
                        <Card.Header style={{ color: "#146a96"}} textAlign="center">{guest.name} {guest.lastname}</Card.Header>
                        <Card.Description>
                          <p style={{ fontSize: "16px" }}>Visitor</p>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ))}
                </>
              ) : (
                selectedGroup !== "" ? (<p>No members or guests in this group</p>) : (<p>Select a group to view members and guests</p>)
              )
            }
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Admin;
