import React from "react";

// Components
import Dashboard from "../components/Dashboard";
import InvestmentForm from "../components/InvestmentForm";

// Stylesheet
import { Container } from "semantic-ui-react";
import "../styles/Guest.css";

const Guest = () => {
  return (
    <Container>
      <Dashboard  />
      <InvestmentForm />
    </Container>
  );
};

export default Guest;
