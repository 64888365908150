import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/members/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/members/";
}

// GET all Members
const getAll = async () => {
  const { data } = await axios.get(API_URL);
  return data;
};
// GET all Members for a specific group
const getMembers = async (id) => {
  const { data } = await axios.get(API_URL + id);
  return data;
};

const getGuests = async () => {
  const { data } = await axios.get(API_URL + "guests/");
  return data;
}

const getFunds = async (id) => {
  const { data } = await axios.get(API_URL + "personal/" + id);
  return data;
}

const editFunds = async (data) => {
  const { data: response } = await axios.put(API_URL, data);
  return response;
}


const memberService = {
  getAll,
  getMembers,
  getGuests,
  getFunds,
  editFunds,
};

export default memberService;
