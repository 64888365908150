import axios from "axios";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import logo from "../assets/FL2F-logo.png";

// Stylesheet
import "../styles/Nav.css";

const Nav = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    navigate("/login");
  };

  if (location.pathname === "/login") return null;

  return (
    <Menu size="huge">
      {user && user.role !== "participant" ? (
        <Menu.Item as={Link} to="/">
          <Image src={logo} size="small" />
        </Menu.Item>
      ) : (
        <Menu.Item>
          <Image src={logo} size="small" />
        </Menu.Item>
      )}
      <Menu.Menu position="right">
        <Menu.Item name="Logout" onClick={onLogout}>
          Logout
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Nav;
