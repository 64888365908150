import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import PrivateRoutes from "./features/utils/PrivateRoutes";
import Admin from "./pages/Admin";
import Guest from "./pages/Guest";
import Login from "./pages/Login";
import Participant from "./pages/Participant";

// Stylesheet
import Nav from "./components/Nav";
import "./styles/App.css";

const App = () => {
  return (
    <Router>
      <Nav />
      <Routes>
        
        <Route path="/login" element={<Login />} />

        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Admin />} />
          <Route path="/participant" element={<Participant />} />
          <Route path="/guest" element={<Guest />} />
        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
};

export default App;
