import { GoogleOAuthProvider } from '@react-oauth/google';
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import 'semantic-ui-css/semantic.min.css';
import App from "./App";
import { store } from "./app/store";

const container = document.getElementById("root");
const root = createRoot(container);

//client ID is a secret it should be a environment variable from the cloud build yml file, 
//add it only for development purposes, do not commit it to the repository

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
