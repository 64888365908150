import axios from "axios";

const API_URL = process.env.NODE_ENV === "production" ? "/api/reset/" : "http://localhost:5000/api/reset/";


const resetFunds = async (id) => {
  const { data } = await axios.post(API_URL + id);
  return data;
}

const resetGroupFunds = async (id) => {
    const { data } = await axios.post(API_URL, {groupid: id});
    return data;
}

const resetService = {
  resetFunds,
  resetGroupFunds,
};

export default resetService;