import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoutes = () => {
    const { user } = useSelector((state) => state.auth);
    const location = useLocation();

    if (!user) {
        // Redirect to login and remember the current location
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    
    if(user.role === "admin" || user.role === "facilitator") {
        return <Outlet />;
    } else if(user.role === "participant") {
        if(location.pathname.includes("participant")) {
            return <Outlet />;
        } else {
            return <Navigate to="/participant" replace />;
        }
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
};

export default PrivateRoutes;
