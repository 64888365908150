import React from "react";

// Components
import Dashboard from "../components/Dashboard";
import InvestmentForm from "../components/InvestmentForm";

// Stylesheet
import { Container } from "semantic-ui-react";
import "../styles/Participant.css";

const Participant = () => {
  return (
    <Container style={{ width: "35%", marginTop: "5em" }}>
      <Dashboard />
      <InvestmentForm />
    </Container>
  );
};

export default Participant;
